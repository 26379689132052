import React from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo";

const embedded = false;
const PrivacyPolicyPage = () => {
  return (
    <Layout embedded={embedded}>
      <div>
        <SEO title="Camping Checklist Support" description="Camping Checklist Support"/>
        <h1>Support</h1>
        <p>
        If you have any questions you can contact us at <a href="mailto:stellarjaysoftware+campingchecklist-support@gmail.com" target="_blank">stellarjaysoftware+campingchecklist-support@gmail.com</a>.<br />
        Please send any feedback or suggestions to <a href="mailto:stellarjaysoftware+campingchecklist-feedback@gmail.com" target="_blank">stellarjaysoftware+campingchecklist-feedback@gmail.com</a>.
      </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage